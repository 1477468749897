<template>
  <v-app>
    <v-container style="text-align: center !important;">
      <div class="mb-5">
        <img src="/learnbread-primary-logo-removebg.png" alt="Learnbread Logo" class="logo" />
        <h2>Forgot Password</h2>
      </div>
      <v-alert class="mt-5" v-if="success" type="success" color="green">{{ successMessage }}</v-alert>
      <v-alert class="mt-5" v-if="error" type="error" color="red">{{ errorMessage }}</v-alert>
      <v-form ref="form" @submit.prevent="submitForm" class="mb-5">
        <v-text-field v-model="formData.email" label="Email" required :rules="emailRules"></v-text-field>
        <v-btn type="submit" class="primary" :disabled="submitting || success">Send Verification Link</v-btn>
      </v-form>
      Have you remembered your Password ? <router-link to="/login">Login </router-link>
      <!-- Don't have an account? <router-link to="/register">Create An Account</router-link> -->
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        email: ''
      },
      error: false,
      errorMessage: '',
      success: false,
      successMessage: '',
      showPassword: false,
      submitting: false
    }
  },
  computed: {
    emailRules() {
      return [
        (v) => !!v || 'Email is required',
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'Invalid email format'
      ]
    }
    /* ,
    errorMessage() {
      return this.$store.state.auth.errorMessage
    } */
  },
  methods: {
    validateForm() {
      if (this.$refs.form) {
        const isValid = this.$refs.form.validate()

        if (!isValid) {
          //this.error = true
          //this.errorMessage = 'Please fix the form errors.'
        }

        return isValid
      } else {
        console.error('Form reference not found.')

        return false
      }
    },
    async submitForm() {
      this.error = false
      
      if (this.validateForm()) {
        this.submitting = true
        try {
          // Make the login action call
          const response = await this.$store.dispatch('auth/forgotPassword', {
            email: this.formData.email.toLowerCase()
          })

          if (response && response.error) {
            // Handle error response
            this.error = true
            this.errorMessage = response.error
            this.submitting = false

          } else {
            
            this.success = true
            this.successMessage = response.message
            this.error = false
            this.submitting = false
            
          }

        } catch (error) {
          console.error('Error during login:', error)
          // Handle login error if needed
        } finally {
          //
        }
      }
    }
  }
}
</script>

<style scoped>
/* Add your custom styles here */

.logo {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}

body {
  background-color: white !important;
}
</style>